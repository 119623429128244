export const links1 = [
    {
        name: 'Account',
        icon: 'mdi-account-outline',
        to: {name: 'Admin Account Profile'}
    },
    // {
    //   name: 'Notification',
    //   to: {name: 'User Notification Settings'}
    // },
        {
        name: 'Change Password',
        icon: 'mdi-key-outline',
        to: {name: 'Admin Change Password'}

    },
]

export const links2 = [
    {
      name: 'Data Privacy',
      icon: 'mdi-account-lock',
      to: ''
    },
    {
      name: 'Terms of Use',
      icon: 'mdi-note-check-outline',
      to: ''
    },
]