<template>
    <section>
      <v-dialog
          v-model="dialog"
          persistent max-width="550"
        >
        <v-card class="pt-2 px-2 pb-4">
            <div class="d-flex flex-row justify-end">
                <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            
            <h3 class="poppins primary--text mb-3 mx-2 mt-0">
              {{ type === 'terms_of_use' ? 'Terms of Use' : 'Data Privacy'  }}
            </h3>

            <div v-for="(item, i) in (type === 'terms_of_use' ? terms_of_use : data_privacy)" :key="i" class="pa-2 f12">
              {{ item }}
            </div>
        </v-card>
      </v-dialog>
      <v-sheet max-height="85vh" min-height="85vh" class="custom-border d-flex">
        <v-navigation-drawer permanent stateless height="85vh" width="220">
          <v-list dense class="drawer">
            <v-list-item v-for="(item, i) in items" :key="i" :to="item.to"
              :class="$route.name == item.to.name ? 'navigation_link_color--text' : ''"
              :style="$route.name == item.to.name ? { borderRight: `5px solid ${getlinkColor} !important`} : ''"
            >
              <v-list-item-title class="roboto f15 fw500">
                {{item.name}}
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <template  v-slot:append>
            <v-list dense>
              <v-list-item v-for="(item, i) in items2" :key="i" @click="() => { dialog = true, i === 0 ? type = 'data_privacy' : type = 'terms_of_use' }">
                <v-list-item-title class="roboto f15 fw500 pointer">
                  {{item.name}}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-navigation-drawer>
        <div class="flex-grow-1">
          <router-view/>
        </div>
      </v-sheet>
    </section>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import { links1, links2 } from '../../constants/settinglinks/admin'
  import data_privacy from "@/constants/dataprivacy"
  import terms_of_use from "@/constants/termsofuse"
  
  export default {  
    data: () => ({
      items: links1,
      items2: links2,
      data_privacy,
      terms_of_use,
      type: '',
      dialog: false
    }),
  
    computed: {
      ...mapState({
        customization: (state) => state.customization
      }),
  
      getlinkColor(){
        return this.customization.hasOwnProperty('navigation_link_color') ? this.customization.navigation_link_color : '##3966D8'
      },
    }
  }
  </script>